// import React, { useState } from 'react';
// import styled from 'styled-components';
// import { FaHome, FaPercent, FaShoppingBasket, FaUser  } from 'react-icons/fa';
// import { FiCompass } from "react-icons/fi";
// import { IoCall } from "react-icons/io5";
// import "./Navbar.css"
// const NavbarContainer = styled.div`
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   background-color: #1c1b22;
//   padding: 10px;
//   border-radius: 25px;
//   width: 90%;
//   max-width: 500px;
//   margin: 20px auto;
// `;

// const NavItem = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   color: ${props => (props.active ? '#FEB509' : '#666')};
//   font-size: 12px;
//   cursor: pointer;
//   position: relative;
//   padding: 10px;
//   transition: all 0.3s ease-in-out;

//   svg {
//     font-size: 24px;
//     margin-right: ${props => (props.active ? '10px' : '0')}; /* Spacing between icon and text */
//     margin-top: ${props => (props.active?'-0px': '0')};
//   }

//   &:hover {
//     color: ##FEB509;
//   }
// `;

// const Label = styled.div`
// //   display: ${props => (props.active ? 'inline' : 'none')};
// display: ${props => (props.active ? 'inline-flex' : 'none')};
//   background-color: #FEB509;
//   color: #1c1b22;
//   border-radius: 25px;
//   padding: 10px 15px;
//   font-size: 14px;
//   transition: all 0.3s ease-in-out;
//   margin-left: 10px;
//   align-items:center;
// `;

// const Textlabel=styled.span`
// display:inline;
// margin-bottom:-10px
// `;


// const Navbar = () => {
//   const [active, setActive] = useState('home');

//   const handleClick = (item) => {
//     setActive(item);
//   };

//   return (
//     <div className='navHolder'>
//     <NavbarContainer>
//       <NavItem active={active==='home'} onClick={() => handleClick('home')}>
//         <FaHome />
//         {active === 'home' && <Label active={active === 'home'}>HOME</Label>}
//         {/* {active === "home" ? <Label active={active === 'home'}> <FaHome /> <Textlabel>Home</Textlabel></Label> : <FaHome />} */}
//       </NavItem>
//       <NavItem active={active === 'search'} onClick={() => handleClick('search')}>
//         <FiCompass />
//         {active === 'search' && <Label active={active === 'search'}>Search</Label>}
//       </NavItem>
//       <NavItem active={active === 'cart'} onClick={() => handleClick('cart')}>
//         <FaShoppingBasket />
//         {active === 'cart' && <Label active={active === 'cart'}>Cart</Label>}
//       </NavItem>
//       <NavItem active={active === 'profile'} onClick={() => handleClick('profile')}>
//         <IoCall />
//         {active === 'profile' && <Label active={active === 'profile'}>Profile</Label>}
//       </NavItem>
//     </NavbarContainer>
//     </div>
//   );
// };

// export default Navbar;


import React, { useState } from 'react';
import styled from 'styled-components';
import { FaHome, FaShoppingBasket } from 'react-icons/fa';
import { FiCompass } from 'react-icons/fi';
import { IoCall } from 'react-icons/io5';
import './Navbar.css';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #1c1b22;
  padding: 10px;
  border-radius: 25px;
  width: 90%;
  max-width: 500px;
  margin: 20px auto;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => (props.active ? '#FEB509' : '#666')};
  font-size: 12px;
  cursor: pointer;
  position: relative;
  padding: 10px;
  transition: all 0.3s ease-in-out;

  svg {
    font-size: 24px;
    margin-right: ${props => (props.active ? '10px' : '0')};
  }

  &:hover {
    color: #FEB509;
  }
`;

const Label = styled.div`
  display: ${props => (props.active ? 'inline-flex' : 'none')};
  background-color: #FEB509;
  color: #1c1b22;
  border-radius: 25px;
  padding: 10px 15px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  margin-left: 10px;
  align-items: center;
`;

const Navbar = ({ homeRef, secondPageRef, aboutRef, featuresRef, contactRef }) => {
  const [active, setActive] = useState('home');

  const handleClick = (item, ref) => {
    setActive(item);
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='navHolder'> 
      <NavbarContainer>
      <NavItem active={active === 'home'} onClick={() => handleClick('home', homeRef)}>
        <FaHome />
        {active === 'home' && <Label active={active === 'home'}>Home</Label>}
      </NavItem>
      <NavItem active={active === 'about'} onClick={() => handleClick('about', aboutRef)}>
        <FiCompass />
        {active === 'about' && <Label active={active === 'about'}>About</Label>}
      </NavItem>
      <NavItem active={active === 'secondPage'} onClick={() => handleClick('secondPage', secondPageRef)}>
        <FaShoppingBasket />
        {active === 'secondPage' && <Label active={active === 'secondPage'}>Explore</Label>}
      </NavItem>
      <NavItem active={active === 'features'} onClick={() => handleClick('features', featuresRef)}>
        <FaShoppingBasket />
        {active === 'features' && <Label active={active === 'features'}>Features</Label>}
      </NavItem>
      <NavItem active={active === 'contact'} onClick={() => handleClick('contact', contactRef)}>
        <IoCall />
        {active === 'contact' && <Label active={active === 'contact'}>Contact</Label>}
      </NavItem>
    </NavbarContainer>
    </div>
    
  );
};

export default Navbar;