// import React from 'react'
// import { GoArrowDown } from "react-icons/go";
// import "./Home.css"
// import VideoSrc from "../assests/videos/homevideo.mp4"
// function Home() {
//   return (
//     <section className='homepage'>
//         <div className='scrollDiv'>
//             <GoArrowDown fontSize={"100px"}/>
//             <h1>Scroll <br/> Down</h1>
//         </div>
//     </section>
//   )
// }

// export default Home
// import React from 'react';
import { useState, useEffect, React } from 'react';
import "./Home.css";
import VideoSrc from "../assets/videos/homevideo.mp4"; 
import VideoSrcPhome from "../assets/videos/homevideophone.mp4"
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import SecondPage from './SecondPage';

function Home() {
    const [Video,setVideoSrc]=useState(VideoSrc);
    useEffect(()=>{
        const handleResize =() => {
            if (window.innerWidth < 400) {
                setVideoSrc(VideoSrcPhome);
            } else {
                setVideoSrc(VideoSrc);
            }
        };
        handleResize();
        window.addEventListener("resize",handleResize);
        return ()=>{
            window.removeEventListener("resize",handleResize);
        };
    },[]);
    const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowDiv(true);
    }, 10000); // 3 seconds delay
    return () => clearTimeout(timeoutId);
  }, []); 

  const scrollToNextSection = () => {
    const nextSection = document.getElementById('secondPage'); // Target the next section
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' }); // Scroll smoothly to the section
    }
  };

  return (
    <div className="homepage">
      {/* Video background */}
      <video autoPlay muted className="videoBackground" preload="metadata">
        <source src={Video} type="video/mp4" />
        {console.log(Video)}
        Your browser does not support the video tag.
      </video>
      {showDiv && <div className='scroll'>
        <MdKeyboardDoubleArrowDown style={{ height:'50px', width:'60px', color:"#FEB509"}} onClick={scrollToNextSection}/> 
        <h1>Scroll <br /> Down</h1>
      </div>}
    </div>
  );
}

export default Home;